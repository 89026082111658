<template>
	<a-card :loading="loading" :hoverable="cClickable" :bordered="bordered" :class="cardCssClasses" @click="handleClick">
		<div class="mycard-content">
			<div class="mycard-header">
				<my-router-link name="company/view" :param-value="company.id" :target="linkTarget" auth-action="read" auth-module="company" @click.stop>
					<ImageLoader :src="company.image_url" class="mycard-image" :size="size" border-type="round">
						<a-icon type="shop" />
					</ImageLoader>
				</my-router-link>
			</div>
			<ul class="mycard-details company-details">
				<li>
					<span class="title text-bold">{{ company.name }}</span>
				</li>
				<li>
					<a-icon type="shop" />
					<span>
						{{ company.billing_name }}
					</span>
				</li>
				<li>
					<PhoneLink :value="company.contact_phone" />
				</li>
				<li>
					<EmailLink :value="company.contact_email" />
				</li>
				<li class="mycard-tags">
					<a-tag v-for="tag of tags" :key="tag.key" :class="tag.class" @click.stop>
						{{ tag.tag }}
					</a-tag>
				</li>
			</ul>
			<a-form-item>
			<a-checkbox
				v-if="selectable"
				:default-checked="company.selected"
				@change="onChangeCheckbox">
			</a-checkbox>
			</a-form-item>
		</div>
		<div class="mycard-invalid-status">
			<span v-if="company.company_status === 'inactive'">
				<a-icon type="close-circle" />
				{{ $tenum('company_status', 'inactive') }}
			</span>
		</div>
	</a-card>
</template>

<script>
import ImageLoader from '@components/common/ImageLoader.vue'
import EmailLink from '@components/common/EmailLink.vue'
import PhoneLink from '@components/common/PhoneLink.vue'
import { Tag, Checkbox } from 'ant-design-vue'
import { mapState } from 'vuex'

export default {
	components: {
		ImageLoader,
		EmailLink,
		PhoneLink,
		'a-tag': Tag,
		'a-checkbox': Checkbox,
	},
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		bordered: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String,
			default: '',
		},
		company: {
			type: null,
			default: () => [],
		},
		linkTarget: {
			type: String,
			default: '_self',
		},
		clickable: {
			type: Boolean,
			default: false,
		},
		showAllTags: {
			type: Boolean,
			default: false,
		},
		selectable: {
			type: Boolean,
			default: false,
		},
		isServiceCenter: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapState('user', ['companies']),
		cClickable() {
			return this.clickable && this.$authorize("read", "company")
		},
		listCompanies() {
			return this.companies.filter((c) => {
				if (this.activeOnly && c.company_status != 'active') return false
				if (this.excludeMine && c.id == this.currentCompany.id) return false
				return true
			})
		},
		currentCompanyType() {
			const company = this.listCompanies.find((company) => company.id === parseInt(this.$route.params.id))
			if (company) {
				return company.company_type
			} else {
				return null
			}
		},
		tags() {
			const tags = []
			if (this.company.company_type != 'service_center' || this.showAllTags) {
				let tagClass = 'mytag-info'
				if (this.company.company_type === 'main_center') tagClass = 'mytag-secondary'
				else if (this.company.company_type === 'service_center') tagClass = ''

				tags.push({
					tag: this.$tenum('company_type', this.company.company_type),
					class: tagClass,
				})
			}
			return tags
		},
		companyLink() {
			if (this.company.id)
				return this.$router.resolve({
					name: 'company/view',
					params: { id: this.company.id },
				}).href
			else return '#'
		},
		cardCssClasses() {
			return [
				'company-card',
				'mycard',
				'mycard-columns',
				'mycard-with-details',
				{
					'mycard-sm': this.size == 'small',
					'mycard-inactive': this.company.company_status === 'inactive',
				},
			]
		},
	},
	methods: {
		handleClick(event) {
			if (!this.cClickable) return
			this.$open({ name: 'company/view', params: { id: this.company.id } }, this.linkTarget)
		},
		onChangeCheckbox() {
			this.$emit('id',this.company.id)
		},
	},
}
</script>
<style lang="less" scoped>
ul.company-details > li {
	font-size: 0.95em;
	margin-bottom: 4px;
}
.model-display-name {
	color: @text-muted;
	font-size: 0.9em;
	margin-top: -4px;
}
</style>
