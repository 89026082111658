<script>
import {isStringEmpty} from "@utils/stringUtil"
import { Icon } from 'ant-design-vue';

export default {
	props : {
		value : {
			type: String,
			default: null
		} ,
		emptyValue : {
			type: String,
			default:null
		} ,
		hideIcon : {
			type : Boolean,
			default : false
		}
	} ,
	render(h) {
		const contents = [];
		if (!this.hideIcon) {
			contents.push(h(Icon, {	props : {type : "mail"	}}))
		}
		if (isStringEmpty(this.value)) {
			const rtn = this.emptyValue === null ? this.$t('common.unspecify') : this.emptyValue
			contents.push(rtn)
			return h('span',contents)
		} else {
			contents.push(this.value)
			return h('a' ,{
				attrs: {
					href: "mailto:"+this.value
				} ,
				on : {
					click(event) {
						event.stopPropagation();
					}
				}
			},contents)
		}
	}
}
</script>
